import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginScreen = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await fetch('/api/validateAuth', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            navigate('/lessons');
            return;
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    
    const url = '/api/login';
    const payload = { username, password };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 401) {
        const errorData = await response.json();
        setErrorMessage(errorData.error);
        return;
      }

      if (response.status === 429) {
        setErrorMessage("Too many login attempts from this IP address, please try again later!");
        return;
      }

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Success:', data);
      localStorage.setItem('token', data.token);
      localStorage.setItem('isAdmin', data.isAdmin);
      navigate('/lessons');
    } catch (error) {
      console.error('Error:', error);
      // Handle other errors here (e.g., show error message to user)
    }
  };

  return (
    <div className="min-h-screen bg-blue-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-md p-8">
      <div className="flex justify-center mb-6">
        <img src="/lps-logo.png" alt="LPS Logo" className="w-64" />
      </div>

      <h1 className="text-center text-xl font-semibold text-gray-700 mb-4">
        Bienvenue sur notre plateforme de formation en ligne
      </h1>

      {errorMessage && (
        <div className="bg-red-500 text-white p-3 rounded-lg mb-4">
        {errorMessage}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <input
        type="text"
        placeholder="Username or Email Address"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="w-full p-3 border rounded-lg"
        required
        />

        <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="w-full p-3 border rounded-lg"
        required
        />

        <button 
        type="submit" 
        className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700"
        >
        Log In
        </button>
      </form>

      </div>
    </div>
  );
};

export default LoginScreen;
