import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface HeaderProps {
    title: string;
    showBanner?: boolean; // Optional prop to control the banner visibility
}

const Header: React.FC<HeaderProps> = ({ title, showBanner = true }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isAdmin = localStorage.getItem('isAdmin') === 'true';

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('isAdmin');
        navigate('/login');
    };

    const handleAdminPanel = () => {
        if (location.pathname === '/admin') {
            navigate('/lessons');
        } else {
            navigate('/admin');
        }
    };

    return (
        <header className="mb-2">
            {showBanner && (
                <div className="bg-blue-800 text-white text-center py-2 mb-4">
                    Consultez nos livres digitaux <a href="https://livrelps.com" target="_blank" rel="noopener noreferrer" className="underline hover:text-blue-400">ici</a>
                </div>
            )}
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold text-blue-600">{title}</h1>
                <div>
                    {isAdmin && (
                        <button 
                            onClick={handleAdminPanel} 
                            className="bg-blue-600 text-white rounded px-4 py-2 mr-4 hover:bg-blue-800"
                        >
                            {location.pathname === '/admin' ? 'Go to Lessons' : 'Admin Panel'}
                        </button>
                    )}
                    <button 
                        onClick={handleLogout} 
                        className="text-blue-600 hover:text-blue-800"
                    >
                        Logout
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;